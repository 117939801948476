<template>
  <footer class="mt-auto bg-white dark:bg-transparent dark:bg-gradient-to-b dark:from-white/[0.03] dark:to-transparent">
    <div class="container">
      <div class="grid gap-x-4 gap-y-10 py-12 sm:grid-cols-3 lg:grid-cols-5 lg:py-[100px]">
        <NuxtLink to="/">
          <img
          class="h-24"
          src="/assets/logos/darkevent-logo.svg"
          alt="DarkEvent logo" />
        </NuxtLink>

        <div>
          <ul class="flex flex-col gap-3 font-bold">
            <li class="mb-3 text-lg font-extrabold text-black dark:text-white">
              Меню
            </li>
            <li>
              <NuxtLink to="/" class="inline-block transition hover:scale-110 hover:text-secondary">Главная
              </NuxtLink>
            </li>
            <li>
              <NuxtLink to="/our-works" class="inline-block transition hover:scale-110 hover:text-secondary">
                Наши работы</NuxtLink>
            </li>
            <li>
              <NuxtLink to="/about-us" class="inline-block transition hover:scale-110 hover:text-secondary">О компании
              </NuxtLink>
            </li>
          </ul>
        </div>
        <div>
          <ul class="flex flex-col gap-3 font-bold">
            <li class="mb-3 text-lg font-extrabold text-black dark:text-white">
              Наше оборудование
            </li>

            <li v-for="category in store.categories" :key="category.id">
              <NuxtLink :to="{
                name: 'equipments-slug',
                params: { slug: category.attributes.slug },
              }" class="inline-block transition hover:scale-110 hover:text-secondary">{{ category.attributes.name }}
              </NuxtLink>
            </li>
          </ul>
        </div>
        <div>
          <ul class="flex flex-col gap-3 font-bold">
            <li class="mb-3 text-lg font-extrabold text-black dark:text-white">
              Информация
            </li>
            <li>
              <NuxtLink to="/#contacts" class="inline-block transition hover:scale-110 hover:text-secondary">Контакты
              </NuxtLink>
            </li>
            <li>
              <NuxtLink to="/privacy" class="inline-block transition hover:scale-110 hover:text-secondary">Политика
                конфиденциальности
              </NuxtLink>
            </li>
          </ul>
        </div>
        <div>
          <ul class="flex flex-col gap-3 font-bold">
            <li class="mb-3 text-lg font-extrabold text-black dark:text-white">
              Контакты
            </li>
            <li>107564, г. Москва, ул. Краснобогатырская, д. 2, стр. 2, под. 28а, эт. 3, оф. 146</li>
            <li>
              <a :href="`tel:${$phone}`" class="inline-block transition hover:scale-110 hover:text-secondary">{{ $phone
              }}</a>
            </li>
            <li>
              <a href="mailto:darkevent@internet.ru"
                class="inline-block transition hover:scale-110 hover:text-secondary">darkevent@internet.ru</a>
            </li>

            <li class="flex flex-row">
              <a href="https://t.me/dark_event" target="_blank" class="flex-row">

                <svg fill="#47bdff" class="h-10" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="-45.5 -45.5 546.00 546.00" xml:space="preserve"
                  stroke="#47bdff" transform="rotate(0)">
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                  <g id="SVGRepo_iconCarrier">
                    <g>
                      <path style="fill-rule:evenodd;clip-rule:evenodd;"
                        d="M0,0v455h455V0H0z M384.814,100.68l-53.458,257.136 c-1.259,6.071-8.378,8.822-13.401,5.172l-72.975-52.981c-4.43-3.217-10.471-3.046-14.712,0.412l-40.46,32.981 c-4.695,3.84-11.771,1.7-13.569-4.083l-28.094-90.351l-72.583-27.089c-7.373-2.762-7.436-13.171-0.084-16.003L373.36,90.959 C379.675,88.517,386.19,94.049,384.814,100.68z">
                      </path>
                      <path style="fill-rule:evenodd;clip-rule:evenodd;"
                        d="M313.567,147.179l-141.854,87.367c-5.437,3.355-7.996,9.921-6.242,16.068 l15.337,53.891c1.091,3.818,6.631,3.428,7.162-0.517l3.986-29.553c0.753-5.564,3.406-10.693,7.522-14.522l117.069-108.822 C318.739,149.061,316.115,145.614,313.567,147.179z">
                      </path>
                    </g>
                  </g>
                </svg>
              </a>

              <a href="mailto:darkevent@internet.ru?subject=Вопрос с сайта" target="_blank" class="flex-row">

                <svg class="h-10" fill="#47b5ff" version="1.1" xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512" xml:space="preserve" stroke="#47b5ff">
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                  <g id="SVGRepo_iconCarrier">
                    <g id="7935ec95c421cee6d86eb22ecd12600d">
                      <path style="display: inline;"
                        d="M170.591,239.406L1.584,429.263c-0.433-1.911-1.084-3.759-1.084-5.852V88.589 c0-2.541,0.71-4.837,1.356-7.144L170.591,239.406z M493.987,65.524c-1.238-0.277-2.424-0.876-3.79-0.876H21.819 c-1.297,0-2.456,0.599-3.769,0.876l237.961,222.734L493.987,65.524z M262.808,313.021c-1.954,1.869-4.372,2.777-6.797,2.777 c-2.419,0-4.842-0.908-6.775-2.777l-62.21-58.217L16.656,446.145c1.698,0.523,3.364,1.207,5.163,1.207h468.378 c1.89,0,3.534-0.684,5.243-1.207L325.082,254.804L262.808,313.021z M341.494,239.406l168.98,189.836 c0.459-1.911,1.025-3.759,1.025-5.831V88.589c0-2.499-0.62-4.837-1.26-7.133L341.494,239.406z">
                      </path>
                    </g>
                  </g>
                </svg>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="bg-gradient-to-r from-[#FCF1F4] to-[#EDFBF9] py-5 dark:border-t-2 dark:border-white/5 dark:bg-none">
      <div class="container">
        <div class="flex flex-col items-center justify-between text-center font-bold dark:text-white md:flex-row">
          <div>DarkEvent {{ new Date().getFullYear() }}</div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup>
import { useAppStore } from "@/stores/index";
const store = useAppStore();

const config = useRuntimeConfig();
</script>
